.App {
  display: flex;
  width: 100%;
  height: 100vh;
}

.logo {
  max-width: 320px;
  padding: 1rem;
  margin: auto;
}
